<template>
  <Loader v-if="!isLoaded"/>
  <Navbar/>
  <div class="container" v-if="isLoaded">
    <div class="row">
      <div class="col">
        <h1>Referee Ranking</h1>
      </div>
    </div>
    <DataTable :value="refs" showGridlines responsiveLayout="scroll">
      <template #header>
        <div class="row">
          <div class="col-auto">
            <Dropdown v-model="selectedSeason"
                      :options="seasons" optionLabel="name" placeholder="Filter by Season"
                      v-on:change="getTableData(selectedSeason.value)"
            />
          </div>
        </div>
      </template>
      <Column field="rank" header="Rank" :style="{'width': '5%'}"/>
      <Column field="referee" header="Name"></Column>
      <Column field="red_cards" header="Red Cards"></Column>
      <Column field="yellow_cards" header="Yellow Cards"></Column>
      <Column field="score" header="Algorithm Score"></Column>
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import Navbar from '@/components/NavBar.vue';
import Loader from '@/components/Loader.vue';

export default {
  name: 'RefereeRanking',
  components: {
    DataTable,
    Column,
    Dropdown,
    Navbar,
    Loader,
  },
  data() {
    return {
      seasons: [],
      selectedSeason: null,
      refs: [],
      latestSeason: null,
      isLoaded: false,
    };
  },
  methods: {
    async getSeasons() {
      await this.axios.get(`https://${this.$store.state.ip}/get-all-seasons`)
        .then((response) => {
          this.seasons = response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getRefs() {
      await this.axios.get(`https://${this.$store.state.ip}/get-all-match-officials/${this.selectedSeason.value}`)
        .then((response) => {
          this.refs = response.data;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getLatestSeason() {
      await this.axios.get(`https://${this.$store.state.ip}/get-latest-season`)
        .then((response) => {
          this.latestSeason = response.data;
          this.getTableData(response.data.season_id);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
    async getTableData(seasonId) {
      this.isLoaded = false;
      await this.axios.get(`https://${this.$store.state.ip}/get-current-table/${seasonId}`)
        .then((response) => {
          this.refs = response.data;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.refs.length; i++) {
            if (i === 0) {
              this.refs[i].rank = 1;
            } else {
              this.refs[i].rank = i + 1;
            }
            this.refs[i].referee = `${this.refs[i].first_name} ${this.refs[i].last_name}`;
          }
          this.isLoaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
    },
  },
  created() {
    this.getLatestSeason();
    this.getSeasons();
  },
};
</script>

<style scoped>
.container {
  margin-bottom: 5em;
}
</style>
